#sidebar-properties {
  .field.widget {
    .buttons {
      display: flex;
      align-items: center;

      button {
        padding: 5px;
        border-radius: 3px;
        margin-right: 3px;
        aspect-ratio: 1/1;
        font-size: 1em;
        line-height: initial;
        &[data-hovered='true'] {
          box-shadow: inset 0 0 0 1px var(--border-color-pressed, #2996da);
        }
        &.active {
          box-shadow: inset 0 0 0 1px var(--border-color-pressed, #2996da);
        }

        svg.icon {
          margin: 0 !important;
          opacity: 0.9;
          vertical-align: top;
        }
      }
    }
  }
}

.field.color {
  .eight.wide.column {
    flex-direction: row;
    align-items: center;

    input {
      padding-left: 8px;
      line-height: 1;
    }
  }
}

.react-aria-ColorSwatch {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.3);
}

.theme-picker-widget {
  .buttons {
    flex: 1 1 100%;
    margin: 1rem 0 0.5rem;
    text-align: center;
  }

  button {
    position: relative;
    width: 32px;
    height: 32px;
    padding: 1rem;
    border: 2px solid #ccc;
    border-radius: 100%;
    margin-right: 0.25rem;

    &.active {
      border: 2px solid red;

      &:after {
        position: absolute;
        top: -0.25rem;
        right: -0.25rem;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 100%;
        background-color: red;
        content: '';
      }
    }
  }
}

.theme-picker-widget button {
  background-color: var(--theme-color);
}

// TODO: Remove when layers are in place
.theme-picker-widget .button.grey,
.theme-picker-widget .button.grey.active,
.theme-picker-widget .ui.grey.button:hover {
  background-color: var(--theme-color);
}
.theme-picker-widget .button.active,
.theme-picker-widget .button.active:hover {
  background-color: var(--theme-color);
}

.buttons-widget {
  button {
    &[data-disabled] {
      border-color: var(--border-color-disabled);
      color: var(--text-color-disabled);
    }
  }
}
