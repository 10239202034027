.code-block-wrapper.dark {
  code[class*='language-'],
  pre[class*='language-'] {
    color: #a7dbf7;
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace !important;

    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
    line-height: 1.5;

    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    text-align: left;
    white-space: pre;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
  }

  pre[class*='language-']::-moz-selection,
  pre[class*='language-'] ::-moz-selection,
  code[class*='language-']::-moz-selection,
  code[class*='language-'] ::-moz-selection {
    background: #03648a;
    text-shadow: none;
  }

  pre[class*='language-']::selection,
  pre[class*='language-'] ::selection,
  code[class*='language-']::selection,
  code[class*='language-'] ::selection {
    background: #03648a;
    text-shadow: none;
  }

  @media print {
    code[class*='language-'],
    pre[class*='language-'] {
      text-shadow: none;
    }
  }

  /* Code blocks */
  pre[class*='language-'] {
    overflow: auto;
    padding: 1em;
    margin: 0.5em 0;
  }

  :not(pre) > code[class*='language-'],
  pre[class*='language-'] {
    background: #011627;
    color: white;
  }

  :not(pre) > code[class*='language-'] {
    padding: 0.1em;
    border-radius: 0.3em;
    white-space: normal;
  }

  .token.string {
    color: #a7dbf7;
  }

  .token.punctuation {
    color: #fff;
  }

  .token.comment {
    color: #999999;
    font-style: italic;
  }

  .token.variable {
    color: #c792ea;
  }

  .token.number {
    color: #8dec95;
  }

  .token.boolean {
    color: #8dec95;
  }

  .token.constant {
    color: #a170c6;
  }

  .token.hexcode {
    color: #a170c6;
  }

  .token.builtin {
    color: #92b6f4;
  }

  .token.char {
    color: #82aaff;
  }

  .token.operator {
    color: #00bff9;
    font-style: italic;
  }

  .token.imports {
    color: #c4a2d8;
  }

  .token.exports {
    color: #c4a2d8;
  }

  .token.class-name {
    color: #6dbdfa;
  }

  .token.function {
    color: #87aff4;
    font-style: italic;
  }

  .token.tag {
    color: #6dbdfa;
  }

  .token.attr-name {
    color: #f7ecb5;
    font-style: italic;
  }

  .token.attr-value {
    color: #a7dbf7;
  }

  .token.selector {
    color: #f7ecb5;
    font-style: italic;
  }

  .token.property {
    color: #91dacd;
  }

  .token.keyword {
    color: #00bff9;
  }

  .token.important,
  .token.bold {
    font-weight: bold;
  }

  .token.italic {
    font-style: italic;
  }
}
